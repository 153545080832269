import { Establishment } from "features/enterprise/domain/models";

export interface AuthSliceState {
  loggedIn: boolean,
  user?: User,
}

export interface LoginResult {
  success: boolean,
  token?: string,
}

export interface ForgotPasswordResult {
  success: boolean,
}

export interface ResetPasswordResult {
  success: boolean,
}

export interface User {
  id: number,
  enterprise?: Enterprise,
  firstName: string,
  lastName: string,
  jobTitle: string,
  phone: string,
  email: string,
  role: UserRole,
  establishmentPermissions?: EstablishmentPermission[], //This is only relevant in case of the ORG_USER role
  isMasquerading?: boolean
}

export interface Enterprise {
  name: string,
  cbeNumber: string,
  confirmed: boolean,
}

export enum UserRole {
  ADMIN = 'ADMIN',
  PROVINCE = 'PROVINCE',
  ORG_ADMIN = 'ORG_ADMIN',
  ORG_USER = 'ORG_USER',
}

export interface EstablishmentPermission {
  establishment: Establishment,
  permission: Permission,
}

export enum Permission {
  READ = 'READ',
  READ_WRITE = 'READ_WRITE',
}