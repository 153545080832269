import { LoginResponse, ResetPasswordResponse, UserResponse } from "features/auth/data/models";
import { EnterpriseDTO } from "features/enterprise/data/models";

export function mapLoginSuccessResponse(json: any): LoginResponse {
  return {
    success: true,
    token: json.token,
  };
}

export function mapLoginFailedResponse(): LoginResponse {
  return { success: false, };
}

export function mapResetPasswordSuccessResponse(): ResetPasswordResponse {
  return { success: true, };
}

export function mapResetPasswordFailedResponse(): ResetPasswordResponse {
  return { success: false, };
}

export function mapUserResponse(json: any): UserResponse {
  return {
    id: json.id,
    enterprise: mapEnterpriseFromJson(json.enterprise),
    firstName: json.firstName,
    lastName: json.lastName,
    jobTitle: json.jobTitle,
    phone: json.phone,
    email: json.email,
    role: json.role,
    establishmentPermissions: json.establishmentPermissions,
  };
}

export function mapEnterpriseFromJson(json: any): EnterpriseDTO | undefined {
  if (json) {
    return {
      cbeNumber: json.cbeNumber,
      name: json.name,
      confirmed: json.confirmed
    }
  }
}