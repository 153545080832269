import { AppConfig } from "config/AppConfig";
import { mapLoginFailedResponse, mapLoginSuccessResponse, mapResetPasswordFailedResponse, mapResetPasswordSuccessResponse, mapUserResponse } from "features/auth/data/mappers";
import { LoginResponse, ResetPasswordResponse, UserResponse } from "features/auth/data/models";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";

export interface AuthApi {
  login(email: string, password: string): Promise<LoginResponse>;
  forgotPassword(email: string): Promise<void>;
  resetPassword(token: string, password: string): Promise<ResetPasswordResponse>;
  getUser(): Promise<UserResponse>;
}

export class AuthApiImpl implements AuthApi {
  async login(email: String, password: String): Promise<LoginResponse> {
    const url = AppConfig.API_URL + '/authenticate';
    const payload = JSON.stringify({ email, password, });
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.unauthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapLoginSuccessResponse(json);
    } else if (response.status === 401) {
      return mapLoginFailedResponse();
    }

    throw new ApiException(response.status);
  }

  async forgotPassword(email: string): Promise<void> {
    const url = AppConfig.API_URL + '/forgotpassword';
    const payload = JSON.stringify({ email, });
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.unauthorizedFetch(url, options);

    if (response.status !== 200) {
      throw new ApiException(response.status);
    }
  }

  async resetPassword(token: string, password: string): Promise<ResetPasswordResponse> {
    const url = AppConfig.API_URL + '/resetpassword';
    const payload = JSON.stringify({ token, password, });
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.unauthorizedFetch(url, options);

    if (response.status === 200) {
      return mapResetPasswordSuccessResponse();
    } else if (response.status === 401) {
      return mapResetPasswordFailedResponse();
    }

    throw new ApiException(response.status);
  }

  async getUser(): Promise<UserResponse> {
    const url = AppConfig.API_URL + '/whoami';
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapUserResponse(json);
    }

    throw new ApiException(response.status);
  }

}