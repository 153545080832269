import styles from "./ErrorBox.module.scss";

interface ErrorBoxProps {
  errorMessage: string,
}

export function ErrorBox(props: ErrorBoxProps) {
  return <div className={ styles.errorBox }>
    <p>{ props.errorMessage }</p>
  </div>;
}
