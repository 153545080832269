import { AuthApi } from "features/auth/data/AuthApi";
import { LoginResponse, ResetPasswordResponse, UserResponse } from "features/auth/data/models";
import { authStorage } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { NotLoggedInException } from "features/common/exceptions/NotLoggedInException";

export class AuthMockApi implements AuthApi {

  async login(email: String, password: String): Promise<LoginResponse> {
    await new Promise(r => setTimeout(r, 1000));

    if (email === 'duo@duo.be' && password === 'duo') {
      return {
        success: true,
        token: 'abcdefghijklmnopqrustuvw',
      };
    } else if (email === 'provincie@duo.be' && password === 'duo') {
      return {
        success: true,
        token: 'zyxwvutsur',
      };
    } else {
      return { success: false, };
    }
  }

  async forgotPassword(email: string): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
    if (email === 'mock@error.be') {
      throw new ApiException(500);
    }
  }

  async resetPassword(token: string, password: string): Promise<ResetPasswordResponse> {
    await new Promise(r => setTimeout(r, 1000));
    if (token === 'mock-fout-token') {
      return { success: false, };
    } else if (token === 'mock-error') {
      throw new ApiException(500);
    }
    return { success: true, };
  }

  async getUser(): Promise<UserResponse> {
    await new Promise(r => setTimeout(r, 1000));
    const token = await authStorage.getToken();
    if (token === 'abcdefghijklmnopqrustuvw') {
      return {
        id: 1,
        enterprise: {
          cbeNumber: 'abc',
          name: 'De Beste NV',
          confirmed: true,
        },
        firstName: 'John',
        lastName: 'Doe',
        jobTitle: 'CEO',
        phone: '+320123456',
        email: 'duo@duo.be',
        role: 'ORG_ADMIN',
        establishmentPermissions: [
          {
            establishment: {
              id: 1,
              cbeNumber: 'est_1',
              name: 'De paddenhoek',
              address: {
                street: 'Straatnaam',
                houseNumber: '1',
                box: 'A',
                postalCode: '8000',
                city: 'Hoofdgemeente',
                country: 'BE',
              },
              activity: 'Openbare instelling',
            },
            permission: 'READ_WRITE',
          },
          {
            establishment: {
              id: 2,
              cbeNumber: 'est_2',
              name: 'De Kikkerhoek',
              address: {
                street: 'Straatnaam',
                houseNumber: '2',
                box: 'A',
                postalCode: '8800',
                city: 'Hoofdgemeente2',
                country: 'BE',
              },
              activity: 'Openbare instelling',
            },
            permission: 'READ',
          },
          {
            establishment: {
              id: 3,
              cbeNumber: 'est_3',
              name: 'De Paardenhoek',
              address: {
                street: 'Straatnaam',
                houseNumber: '2',
                box: 'A',
                postalCode: '8800',
                city: 'Hoofdgemeente2',
                country: 'BE',
              },
              activity: 'Openbare instelling',
            },
            permission: 'READ',
          }
        ]
      };
    } else if (token === 'zyxwvutsur') {
      return {
        id: 1,
        firstName: 'Pro',
        lastName: 'Vincie',
        jobTitle: 'CEO',
        phone: '+320123456',
        email: 'province@duo.be',
        role: 'PROVINCE',
      };
    } else {
      throw new NotLoggedInException();
    }
  }

}